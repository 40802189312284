<!-- OppDetailsHeader.vue -->

<template>

  <div v-if="!isFetching('fetchingOpp')">
    <div class="d-flex mb-3 align-items-center">
      <div class="col-8">

        <!-- Title -->
        <div class="fs-3 fw-semibold">
          <div v-if="!isEditingTitle">
            <div class="d-flex align-items-center edit-title-trigger">
              <div v-if="record.fields['Title']">
                <div class="text-truncate" style="max-width: 600px;">
                  {{ record.fields['Title'] }}
                </div>
              </div>

              <div v-else>
                Untitled Project
              </div>

              <div class="fs-6 fw-normal edit-title-button ms-2">
                <a href="#" class="small" @click.prevent="editTitle"><i class="bi bi-pencil"></i> Edit</a>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="d-flex" style="margin-bottom: 0.25rem">
              <input type="text" class="form-control" v-model="localTitle" placeholder="A descriptive title for this opp">

              <button class="btn btn-sm btn-primary ms-2" @click="saveTitle" :disabled="isSavingTitle">
                <div v-if="isSavingTitle">
                  <div class="spinner-border spinner-border-sm" role="status"></div>
                </div>
                <div v-else>
                  Save
                </div>
              </button>

              <button class="btn btn-sm btn-outline-secondary ms-2" @click="cancelEditTitle">Cancel</button>
            </div>
          </div>
        </div>

        <!-- Client -->
        <div class="mb-2 small">
          <div v-if="!isEditingClient">
            <div class="d-flex edit-client-trigger">
              <i class="bi bi-stars me-1"></i> {{ record.fields['Quote No.'] }}<span v-if="record.fields['OID']">-{{ record.fields['OID'] }}</span>
              <i class="ms-3 me-1 bi bi-building-fill"></i> <a :href="`/companies/${record.fields['Company ID']}`" target="_blank">{{ record.fields['Company Lookup'] ? record.fields['Company Lookup'].join(', ') : '' }}</a>
              <i class="ms-3 me-1 bi bi-person-circle"></i> <a :href="`/contacts/${record.fields['Contact ID']}`" target="_blank">{{ record.fields['Contact Lookup'] ? record.fields['Contact Lookup'].join(', ') : '' }}</a>

              <div class="edit-client-button ms-2">
                <a href="#" @click.prevent="editClient"><i class="bi bi-pencil"></i> Edit</a>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="d-flex">
              <div class="col">
                <div v-if="!isFetching('fetchingCompanies')">
                  <v-select v-model="selectedCompany" :options="allCompaniesOptions" placeholder="Select company..." :clearable="false">
                    <template v-slot:option="option">
                      <div class="text-wrap">{{ option.label }}</div>
                      <!-- <div class="small text-muted">Preferred currency: {{ option.currency }}</div>
                      <div class="small text-muted">Preferred PM: {{ Array.isArray(option.pm) ? [].concat(...option.pm).join(', ') : '' }}</div>
                      <div class="small text-muted text-wrap">Contacts: {{ Array.isArray(option.contacts) ? [].concat(...option.contacts).join(', ') : '' }}</div> -->
                    </template>

                    <template v-slot:open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                      </span>
                    </template>
                  </v-select>
                </div>

                <div v-else>
                  <v-select placeholder="Loading companies..." disabled>
                    <template v-slot:open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>

              <div class="col ms-2">
                <div v-if="!isFetching('fetchingContacts')">
                  <v-select v-model="selectedContact" :options="allContactsOptions" placeholder="Select contact..." :clearable="false">
                    <template v-slot:option="option">
                      <div class="text-wrap">{{ option.label }}</div>
                      <div class="small text-muted text-wrap">Email: {{ option.email }}</div>
                      <div class="small text-muted text-wrap">Companies: {{ Array.isArray(option.companies) ? [].concat(...option.companies).join(', ') : '' }}</div>
                    </template>

                    <template v-slot:open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                      </span>
                    </template>
                  </v-select>
                </div>

                <div v-else>
                  <v-select placeholder="Loading contacts..." disabled>
                    <template v-slot:open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>

              <button class="btn btn-sm btn-primary ms-2" @click="saveClient" :disabled="isSavingClient || isFetching('fetchingCompanies') || isFetching('fetchingContacts')">
                <div v-if="isSavingClient">
                  <div class="spinner-border spinner-border-sm" role="status"></div>
                </div>
                <div v-else>
                  Save
                </div>
              </button>

              <button class="btn btn-sm btn-outline-secondary ms-2" @click="cancelEditClient">Cancel</button>
            </div>
          </div>
        </div>

        <!-- Status -->
        <div class="small">
          <div v-if="!isEditingStatus">
            <div class="d-flex align-items-center edit-status-trigger">
              <span :class="['badge custom-badge me-2', mixinGetBadgeClass(record.fields['Status Lookup'])]">{{ record.fields['Status Lookup'] }}</span>
              <span :class="['badge custom-badge me-1', mixinGetCurrencyBadgeClass(record.fields['Currency Lookup'])]">{{ record.fields['Currency Lookup'] }}</span>

              <div class="edit-status-button ms-2">
                <a href="#" @click.prevent="editStatus"><i class="bi bi-pencil"></i> Edit</a>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="d-flex">
              <div class="col">
                <v-select v-model="localStatus" :options="allStatusesOptions" placeholder="Status..." :clearable="false">
                  <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                  </template>
                </v-select>
              </div>

              <div class="col ms-2">
                <v-select v-model="localCurrency" :options="allCurrenciesOptions" placeholder="Select..." :clearable="false">
                  <template v-slot:open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                    </span>
                  </template>
                </v-select>
              </div>

              <button class="btn btn-sm btn-primary ms-2" @click="saveStatus" :disabled="isSavingStatus">
                <div v-if="isSavingStatus">
                  <div class="spinner-border spinner-border-sm" role="status"></div>
                </div>
                <div v-else>
                  Save
                </div>
              </button>

              <button class="btn btn-sm btn-outline-secondary ms-2" @click="cancelEditStatus">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Stats: Revenue -->
      <div class="d-flex col small">
        <div class="col text-end ms-auto">
          <div class="">
            <p class="mb-0">Revenue</p>
            <div class="text-muted">{{ record.fields['Currency Lookup'] ? getCurrencySymbol[record.fields['Currency Lookup']] : '' }}{{ mixinFormatNumber(record.fields['4 - Total in Opp Currency']) }}</div>
          </div>
        </div>

        <!-- Stats: Vendor Costs -->
        <div v-if="mixinUserInRoles(['Admin'])" class="col text-end">
          <div class="">
            <p class="mb-0">Costs in Euro</p>
            <div class="text-muted">€{{ mixinFormatNumber(record.fields['10 - Costs in €']) }}</div>
          </div>
        </div>

        <!-- Stats: Profit -->
        <div v-if="mixinUserInRoles(['Admin'])" class="col text-end">
          <div class="">
            <p class="mb-0">Profit in Euro</p>
            <div class="text-muted">€{{ mixinFormatNumber(record.fields['11 - Profit in €']) }}</div>
          </div>
        </div>

        <!-- Stats: Profit Margin -->
        <div v-if="mixinUserInRoles(['Admin', 'PM'])" class="col text-end">
          <div class="">
            <p class="mb-0">Profit Margin</p>
            <div class="text-muted" :class="mixinGetPercentageClass(record.fields['12 - Margin'])">{{ mixinFormatMargin(record.fields['12 - Margin']) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OppDetailsHeader',
  emits: [
    'showAlert',
    'showError',
    'update:title',
    'updateClient',
    'update:status',
  ],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isEditingTitle: false,
      isSavingTitle: false,
      localTitle: this.record.fields['Title'] || '',

      isEditingClient: false,
      isSavingClient: false,
      localCompany: null,
      localContact: null,

      isEditingStatus: false,
      isSavingStatus: false,
      localStatus: this.record.fields['Status'] || '',
      localCurrency: this.record.fields['Currency'] || '',
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
      'getCurrencySymbol',

      'allCompaniesOptions',
      'allContactsOptions',
      'allStatusesOptions',
      'allCurrenciesOptions',
    ]),

    // It is generally not recommended to modify props directly in a child component because props are meant to be a one-way data flow from parent to child.
    // Instead, we should emit events to notify the parent component to update the prop value (like 'update:title'). This ensures better maintainability and data flow management.
    // ESLint only catches direct mutations in templates and simple assignments. The mixin method is modifying the prop indirectly through a method call, which ESLint doesn't flag, and so this works.
    selectedCompany: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Company', optionsArrayName: 'allCompaniesOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Company', value: value, optionsArrayName: 'allCompaniesOptions' });
      }
    },

    selectedContact: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Contact', optionsArrayName: 'allContactsOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Contact', value: value, optionsArrayName: 'allContactsOptions' });
      }
    },
  },

  watch: { },

  methods: {
    editTitle() {
      this.isEditingTitle = true;
    },

    saveTitle() {
      this.isSavingTitle = true;
      this.$emit('update:title', this.localTitle);
    },

    cancelEditTitle() {
      this.isEditingTitle = false;
      this.localTitle = this.record.fields['Title'] || '';
    },

    editClient() {
      this.isEditingClient = true;

      // console.log('Current Company ID:', this.record.fields['Company']); // debug
      // console.log('Available Companies:', this.allCompaniesOptions); // debug

      const originalCompany = this.allCompaniesOptions.find(
        company => {
          // console.log('Comparing:', company.value, 'with', this.record.fields['Company'][0]); // debug
          return company.value === this.record.fields['Company'][0];
        }
      );

      // console.log('Original company:', originalCompany); // debug

      const originalContact = this.allContactsOptions.find(
        contact => {
          return contact.value === this.record.fields['Contact'][0];
        }
      );

      // This is useful for those cases where companies are not loaded yet,
      // so when user cancels before companies loaded, we don't get an error
      this.localCompany = originalCompany || null;
      this.localContact = originalContact || null;
    },

    saveClient() {
      this.isSavingClient = true;
      this.$emit('updateClient');
    },

    cancelEditClient() {
      this.isEditingClient = false;

      // Add checks to prevent undefined errors
      if (!this.localCompany || !this.localContact) {
        // console.log('No local company stored, or companies were not loaded yet'); // debug
        return;
      }

      // console.log('Cancelling with company:', this.localCompany); // debug

      this.mixinSetSelectedField({
        fieldName: 'Company',
        value: this.localCompany,
        optionsArrayName: 'allCompaniesOptions'
      });

      this.mixinSetSelectedField({
        fieldName: 'Contact',
        value: this.localContact,
        optionsArrayName: 'allContactsOptions'
      });
    },

    editStatus() {
      this.isEditingStatus = true;
    },

    saveStatus() {
      this.isSavingStatus = true;
      this.$emit('update:status', this.localStatus.value, this.localCurrency.value);
    },

    cancelEditStatus() {
      this.isEditingStatus = false;
      this.localStatus = this.record.fields['Status'] || '';
      this.localCurrency = this.record.fields['Currency'] || '';
    },
  },

  created() { },
};
</script>