<!-- OppDetails.vue -->

<template>

  <div v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])">
    <!-- Profit margin banner -->
    <div v-if="mixinUserInRoles(['Admin', 'PM'])">
      <div v-if="!isFetching('fetchingOpp') && isDataFetched">
        <div v-if="record.fields['Jobs ID'] && record.fields['12 - Margin'] < 70" class="alert alert-warning p-2 small">
          <i class="bi bi-exclamation-triangle-fill"></i> <strong>Profit margin is lower than 70%.</strong> Our goal is to achieve margins higher than 80%. Consider negotiating the vendor rate to reach this target.
        </div>
      </div>
    </div>

    <OppDetailsHeader ref="oppDetailsHeader" v-if="isDataFetched" :record="record" @update:title="updateTitle" @updateClient="updateClient" @update:status="updateStatus" @showAlert="showAlert" @showError="showError" />

    <!-- Navigation -->
    <div class="d-flex mb-2">
      <!-- Tabs -->
      <div class="col">
        <ul class="nav nav-tabs" v-show="tabsVisible">
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#oppDetails" aria-selected="true" role="tab">Overview</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#oppJobs" aria-selected="false" role="tab">Jobs</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#oppInvoicing" aria-selected="false" role="tab">Invoicing</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#oppDelivery" aria-selected="false" role="tab">Post-Delivery</a>
          </li>
        </ul>
      </div>

      <!-- Secondary actions -->
      <div v-if="!isFetching('fetchingOpp') && isDataFetched" class="col-4 d-flex justify-content-end align-items-start border-bottom">
        <!-- Delete Opp -->
        <a v-if="!record.fields['Jobs ID']" @click="openDeleteOppModal()" :disabled="isDeleting('deletingOpp')" class="btn btn-sm btn-danger">
          <i class="bi bi-trash me-1"></i> Delete Opp
        </a>

        <!-- Copy Quote Link -->
        <a v-if="record.fields['Jobs ID']" class="btn btn-sm btn-outline-secondary ms-2" @click="copyToClipboard(record.fields['Quote Link'])">
          <i class="bi me-1" :class="copied ? 'bi-check' : 'bi-clipboard'"></i> {{ copied ? 'Copied' : 'Copy Quote Link' }}
        </a>

        <!-- <a class="btn btn-sm btn-outline-secondary ms-2" @click="openMarkAsFinishedModal">Mark as Finished</a> -->

        <!-- More Options -->
        <div class="dropdown">
          <a class="btn btn-sm btn-outline-secondary dropdown-toggle ms-2" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-list"></i> More</a>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a v-if="record.fields['Jobs ID']" :href="record.fields['Quote Link']" class="dropdown-item small" target="_blank">
                <i class="bi bi-box-arrow-up-right me-1"></i> View Quote
              </a>
            </li>
            <li>
              <a class="dropdown-item small" href="#" @click="copyInternalLinkToClipboard(`https://app.languageburo.com/opps/${record.fields['Opp ID']}`)">
                <i class="bi bi-clipboard me-1"></i> Copy Internal Link
              </a>
            </li>
            <li>
              <a class="dropdown-item small" href="#" data-bs-toggle="modal" data-bs-target="#duplicateOppModal">
                <i class="bi bi-copy me-1"></i> Duplicate Opp
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <div class="tab-content mb-3">
      <!-- Tab: Opp Details -->
      <div id="oppDetails" class="tab-pane show active">
        <div v-if="!isFetching('fetchingOpp') && isDataFetched">
          <!-- Created On -->
          <!-- <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Created On
            </div>

            <div class="col-5">
              {{ mixinFormatDate(record.fields['Created On']) }}
            </div>
          </div> -->

          <!-- Created By -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Created By
            </div>

            <div class="col-5">
              <v-select v-if="!isFetching('fetchingTeam')" v-model="selectedCreatedBy" :options="allTeamOptions" placeholder="Select..." :clearable="false">
                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>

              <div v-else class="d-inline-block">
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Forwarded By -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Forwarded By
            </div>

            <div class="col-5">
              <v-select v-if="!isFetching('fetchingTeam')" v-model="selectedForwardedBy" :options="allTeamOptions" placeholder="Select..." :clearable="false">
                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>

              <div v-else class="d-inline-block">
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>

          <!-- PM -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              PM
            </div>

            <div class="col-5">
              <v-select v-if="!isFetching('fetchingPMs')" v-model="selectedPM" :options="allPMsOptions" placeholder="Select..." :clearable="false">
                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>

              <div v-else class="d-inline-block">
                <div class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Order ID -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Order ID
            </div>

            <div class="col-2">
              <input v-model="record.fields['OID']" type="number" class="form-control">
            </div>
          </div>

          <!-- Order Start Date -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Order Start Date
            </div>

            <div class="col-2">
              <input v-model="record.fields['Order Start Date']" type="date" class="form-control">
            </div>
          </div>

          <!-- Order Delivery Date -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Order Delivery Date
            </div>

            <div class="col-2">
              <input v-model="record.fields['Order Delivery Date']" type="date" class="form-control">
            </div>
          </div>

          <!-- MateCat Link -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              MateCat Link
            </div>

            <div class="col-8">
              <input v-model="record.fields['MateCat Link']" type="text" class="form-control">
            </div>
          </div>

          <!-- Company Notes -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Company Notes
            </div>

            <div class="col-8">
              <div v-if="record.fields['Company Notes (taken from Company)'] ? record.fields['Company Notes (taken from Company)'].join(', ') : ''">
                <!-- <div class="small text-break" style="white-space: pre-line;">{{ record.fields['Company Notes (taken from Company)'] ? record.fields['Company Notes (taken from Company)'].join(', ') : '' }}</div> -->
                <textarea class="form-control form-control-sm" rows="3" v-model="record.fields['Company Notes (taken from Company)']" readonly></textarea>
              </div>
              <div v-else>
                <div class="text-muted small">No notes yet.</div>
              </div>
            </div>
          </div>

          <!-- PM Company Notes -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              PM Company Notes
            </div>

            <div class="col-8">
              <div v-if="record.fields['PM Notes (taken from Company)'] ? record.fields['PM Notes (taken from Company)'].join(', ') : ''">
                <!-- <div class="small text-break" style="white-space: pre-line;">{{ record.fields['PM Notes (taken from Company)'] ? record.fields['PM Notes (taken from Company)'].join(', ') : '' }}</div> -->
                <textarea class="form-control form-control-sm" rows="3" v-model="record.fields['PM Notes (taken from Company)']" readonly></textarea>
              </div>
              <div v-else>
                <div class="text-muted small">No notes yet.</div>
              </div>
            </div>
          </div>

          <!-- Public Notes -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Public Notes
              <div class="form-text">Visible in the quote link</div>
            </div>

            <div class="col-8">
              <textarea class="form-control" rows="3" v-model="record.fields['Public Notes']"></textarea>
            </div>
          </div>

          <!-- Private Notes -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Private Notes
              <div class="form-text">Only visible internally</div>
            </div>

            <div class="col-8">
              <textarea class="form-control" rows="3" v-model="record.fields['Private Notes']"></textarea>
            </div>
          </div>

          <!-- Save -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
            </div>

            <div class="col-5">
              <button class="btn btn-sm btn-primary" @click.prevent="updateRecord()" :disabled="isUpdating('updatingOpp') || isFetching('fetchingTeam')">
                <div v-if="isUpdating('updatingOpp') || isFetching('fetchingTeam')">
                  <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
                </div>

                <div v-else>
                  Save
                </div>
              </button>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="d-flex justify-content-center p-5">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Tab: Jobs -->
      <OppDetailsJobsTab ref="oppDetailsJobsTab" v-if="isDataFetched" :oppId="recordId" :oppCurrency="record.fields['Currency']" :opp1UsdToEur="record.fields['Opp 1 USD to EUR']" :opp1GbpToEur="record.fields['Opp 1 GBP to EUR']" @showAlert="showAlert" @showError="showError" @fetchOpp="fetchRecord" />

      <!-- Tab: Invoicing -->
      <div id="oppInvoicing" class="tab-pane">
        <div v-if="!isFetching('fetchingOpp') && isDataFetched">
          <!-- Client PO -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Client PO
            </div>

            <div class="col-5">
              <textarea v-model="record.fields['Client PO']" class="form-control" rows="3"></textarea>
            </div>
          </div>

          <!-- PO Files -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              PO Files
            </div>

            <div class="col-5">
              <button @click="openFilePicker" class="btn btn-sm btn-outline-secondary"><i class="bi bi-upload me-1"></i> Upload Files</button>

              <span class="text-muted small ps-2">
                After uploading or deleting files, save your changes by clicking in "Save"
              </span>

              <ul v-if="record.fields['Client PO Files']" class="mt-2 mb-0 ps-0 list-unstyled">
                <li v-for="(file, index) in record.fields['Client PO Files']" :key="index">
                  <a :href="file.url" target="_blank">
                    {{ file.filename }}
                  </a>
                  <a href="#" @click.prevent="deleteFile(index)" class="btn btn-sm btn-outline-secondary ms-2"><i class="bi bi-trash-fill"></i></a>
                </li>
              </ul>
            </div>
          </div>

          <!-- Invoice -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Invoice Number
            </div>

            <div class="col-2">
              <input v-model="record.fields['Invoice']" type="text" class="form-control">
            </div>
          </div>

          <!-- Invoice Files -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Invoice Files
            </div>

            <div class="col-5">
              <button @click="openFilePickerForInvoiceFiles" class="btn btn-sm btn-outline-secondary"><i class="bi bi-upload me-1"></i> Upload Files</button>

              <span class="text-muted small ps-2">
                After uploading or deleting files, save your changes by clicking in "Save"
              </span>

              <ul v-if="record.fields['Invoice Files']" class="mt-2 mb-0 ps-0 list-unstyled">
                <li v-for="(file, index) in record.fields['Invoice Files']" :key="index">
                  <a :href="file.url" target="_blank">
                    {{ file.filename }}
                  </a>
                  <a href="#" @click.prevent="deleteInvoiceFile(index)" class="btn btn-sm btn-outline-secondary ms-2"><i class="bi bi-trash-fill"></i></a>
                </li>
              </ul>
            </div>
          </div>

          <!-- VAT % -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              VAT in %
            </div>

            <div class="col-2">
              <input v-model="record.fields['VAT %']"  type="number" class="form-control">
            </div>
          </div>

          <!-- Disc % -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Discount in %
            </div>

            <div class="col-2">
              <input v-model="record.fields['Disc %']"  type="number" class="form-control">
            </div>

            <div class="col-3">
              <span class="text-muted small ps-2">
                <span v-if="record.fields['Disc %']">
                  The discount is {{ record.fields['Currency Lookup'] ? getCurrencySymbol[record.fields['Currency Lookup']] : '' }}{{ mixinFormatNumber((record.fields['1 - Sub Total in Opp Currency'] * record.fields['Disc %'] / 100)) }}
                </span>
              </span>
            </div>
          </div>

          <!-- Reason for Disc -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Reason for Discount
            </div>

            <div class="col-5">
              <input v-model="record.fields['Reason for Disc']" type="text" class="form-control">
            </div>
          </div>

          <!-- Save -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
            </div>

            <div class="col-5">
              <button class="btn btn-sm btn-primary" @click.prevent="updateRecord()" :disabled="isUpdating('updatingOpp') || isFetching('fetchingTeam')">
                <div v-if="isUpdating('updatingOpp') || isFetching('fetchingTeam')">
                  <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
                </div>

                <div v-else>
                  Save
                </div>
              </button>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="d-flex justify-content-center p-5">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Tab: Post-Delivery -->
      <!-- Note: I couldn't isolate this to a different component. It stucks loading and won't pass the isFetching and isDataFetched flags -->
      <div id="oppDelivery" class="tab-pane">
        <div v-if="!isFetching('fetchingOpp') && isDataFetched">
          <!-- Delivery Confirmed? -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Delivery Confirmed?
            </div>

            <div class="col-2">
              <input v-model="record.fields['Delivery Confirmed?']" type="text" class="form-control">
            </div>
          </div>

          <!-- Quolity Issues Dropdown -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Quality Issues Dropdown
            </div>

            <div class="col-2">
              <v-select v-model="record.fields['Quality Issues Dropdown']" :options="['Yes', 'No', 'N/A']" placeholder="Select..." :clearable="false">
                <template v-slot:open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                  </span>
                </template>
              </v-select>
            </div>
          </div>

          <!-- Quolity Issues? -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Quality Issues Notes
            </div>

            <div class="col-5">
              <textarea class="form-control" rows="3" v-model="record.fields['Quality Issues?']"></textarea>
            </div>
          </div>

          <!-- Client Feedback -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Client Feedback
            </div>

            <div class="col-5 text-break" style="white-space: pre-line;">
              <textarea class="form-control" rows="3" v-model="record.fields['Client Feedback']"></textarea>
            </div>
          </div>

          <!-- Solution -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Solution
            </div>

            <div class="col-5 text-break" style="white-space: pre-line;">
              <textarea class="form-control" rows="3" v-model="record.fields['Solution']"></textarea>
            </div>
          </div>

          <!-- Action -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
              Action
            </div>

            <div class="col-5 text-break" style="white-space: pre-line;">
              <textarea class="form-control" rows="3" v-model="record.fields['Action']"></textarea>
            </div>
          </div>

          <!-- Outcome -->
          <div class="d-flex pt-2 pb-2 align-items-center">
            <div class="col-3 small text-end pe-2">
              Outcome
            </div>

            <div class="col-5 text-break" style="white-space: pre-line;">
              <textarea class="form-control" rows="3" v-model="record.fields['Outcome']"></textarea>
            </div>
          </div>

          <!-- Save -->
          <div class="d-flex pt-1 pb-1 align-items-center">
            <div class="col-3 small text-end pe-2">
            </div>

            <div class="col-5">
              <button class="btn btn-sm btn-primary" @click.prevent="updateRecord()" :disabled="isUpdating('updatingOpp') || isFetching('fetchingTeam')">
                <div v-if="isUpdating('updatingOpp') || isFetching('fetchingTeam')">
                  <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
                </div>

                <div v-else>
                  Save
                </div>
              </button>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="d-flex justify-content-center p-5">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Opp -->
    <DeleteOppModal ref="deleteOppModal" :oppId="recordId" @showAlert="showAlert" @showError="showError" />

    <!-- Duplicate Opp -->
    <DuplicateOppModal ref="duplicateOppModal" v-if="isDataFetched" :opp="record" @showAlert="showAlert" @showError="showError" />
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>

</template>

<script>
/* global filestack */
/* global bootstrap */
import { mapGetters } from 'vuex';
import { sendMessageToSlack } from '@/services/slack.js';
import OppDetailsHeader from '@/components/OppDetailsHeader.vue';
import OppDetailsJobsTab from '@/components/OppDetailsJobsTab.vue';
import DeleteOppModal from '@/components/DeleteOppModal.vue';
import DuplicateOppModal from '@/components/DuplicateOppModal.vue';

export default {
  name: 'OppDetails',

  emits: [
    'showAlert',
    'showError',
  ],

  components: {
    OppDetailsHeader,
    OppDetailsJobsTab,
    DeleteOppModal,
    DuplicateOppModal,
  },

  data() {
    return {
      isDataFetched: false,

      recordId: this.$route.params.id,

      sectionEditMode: {
        sectionOne: true,
        sectionTwo: true,
        sectionThree: true,
        sectionFour: true,
      },

      record: { },
      tempRecord: null,

      tableName: 'Opportunities',
      stateVariable: 'opp',
      creatingStateVariable: 'creatingOpp',
      fetchingStateVariable: 'fetchingOpp',
      updatingStateVariable: 'updatingOpp',
      deletingStateVariable: 'deletingOpp',

      tabsVisible: false,
      copied: false,
    };
  },

  computed: {
    ...mapGetters([
      'allTeamOptions',
      'allPMsOptions',
      'allCompaniesOptions',
      'allContactsOptions',
      'allStatusesOptions',
      'allCurrenciesOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',

      'getCurrencySymbol',
    ]),

    company() {
      return this.record.fields['Company Lookup'] ? this.record.fields['Company Lookup'].join(', ') : '';
    },

    contact() {
      return this.record.fields['Contact Lookup'] ? this.record.fields['Contact Lookup'].join(', ') : '';
    },

    contactEmail() {
      return this.record.fields['Email (from "Contact" table)'] ? this.record.fields['Email (from "Contact" table)'].join(', ') : '';
    },

    quoteNumber() {
      return this.record.fields['Quote No.'];
    },

    quoteUrl() {
      return `https://app.languageburo.com/quotes/${this.record.fields['Opp ID']}`;
    },

    oppUrl() {
      return `https://app.languageburo.com/opps/${this.record.fields['Opp ID']}`;
    },

    createdBy() {
      return this.record.fields['Created By Lookup'] ? this.record.fields['Created By Lookup'].join(', ') : '';
    },

    createdByEmail() {
      return this.record.fields['Created By Email'] ? this.record.fields['Created By Email'].join(', ') : '';
    },

    currency() {
      return this.record.fields['Currency Lookup'] ? this.getCurrencySymbol[this.record.fields['Currency Lookup']] : '';
    },

    value() {
      return this.mixinFormatNumber(this.record.fields['4 - Total in Opp Currency']);
    },

    privateNotes() {
      return this.record.fields['Private Notes'];
    },

    // We use these because they are async calls. "Status" and "Currency" are just hardcoded in the store, so there's no need to wait for the data to be fetched.
    selectedCreatedBy: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Created By', optionsArrayName: 'allTeamOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Created By', value: value, optionsArrayName: 'allTeamOptions' });
      }
    },

    selectedForwardedBy: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Forwarded By', optionsArrayName: 'allTeamOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Forwarded By', value: value, optionsArrayName: 'allTeamOptions' });
      }
    },

    selectedPM: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'PM', optionsArrayName: 'allPMsOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'PM', value: value, optionsArrayName: 'allPMsOptions' });
      }
    },
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    async updateTitle(newTitle) {
      try {
        await this.$store.dispatch('updateRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          updatedFields: {
            'Title': newTitle,
          },
          updatingStateVariable: this.updatingStateVariable,
        });

        this.$emit('showAlert', 'success', 'Successfully updated title');
        await this.fetchRecord();

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error updating title: ${error.message}`);
        console.error(error);

      } finally {
        this.$refs.oppDetailsHeader.isSavingTitle = false;
        this.$refs.oppDetailsHeader.isEditingTitle = false;
      }
    },

    async updateClient() {
      try {
        await this.$store.dispatch('updateRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          updatedFields: {
            'Company': this.record.fields['Company'],
            'Contact': this.record.fields['Contact'],
          },
          updatingStateVariable: this.updatingStateVariable,
        });

        this.$emit('showAlert', 'success', 'Successfully updated client');
        await this.fetchRecord();

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error updating client: ${error.message}`);
        console.error(error);

      } finally {
        this.$refs.oppDetailsHeader.isSavingClient = false;
        this.$refs.oppDetailsHeader.isEditingClient = false;
      }
    },

    async updateStatus(newStatus, newCurrency) {
      try {
        const previousStatus = this.record.fields['Status Lookup'];

        await this.$store.dispatch('updateRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          updatedFields: {
            'Status': newStatus,
            'Currency': newCurrency,
          },
          updatingStateVariable: this.updatingStateVariable,
        });

        await this.fetchRecord();

        if (previousStatus !== newStatus) {
          await this.sendStatusChangeMessage(newStatus);
        }

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error updating status and/or currency: ${error.message}`);
        console.error(error);

      } finally {
        this.$refs.oppDetailsHeader.isSavingStatus = false;
        this.$refs.oppDetailsHeader.isEditingStatus = false;
      }
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 3000); // Reset after 3 seconds
      }, err => {
        console.error('Could not copy text: ', err);
      });
    },

    copyInternalLinkToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        // Optional: Display a message or feedback to the user
        this.$emit('showAlert', 'success', 'Internal link copied to clipboard');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    },

    async openDeleteOppModal() {
      this.$refs.deleteOppModal.openModal();
    },

    toggleSectionEditMode(section) {
      this.sectionEditMode[section] = !this.sectionEditMode[section];

      if (this.sectionEditMode[section]) {
        this.tempRecord = JSON.parse(JSON.stringify(this.record));
      }
    },

    cancelSectionEditMode(section) {
      this.sectionEditMode[section] = false;
      this.record = JSON.parse(JSON.stringify(this.tempRecord));
    },

    openFilePicker() {
      const options = {
        onUploadDone: (result) => {
          this.handleFileUpload(result.filesUploaded);
        },
      };

      const picker = this.client.picker(options);
      picker.open();
    },

    openFilePickerForInvoiceFiles() {
      const options = {
        onUploadDone: (result) => {
          this.handleInvoiceFilesUpload(result.filesUploaded);
        },
      };

      const picker = this.client.picker(options);
      picker.open();
    },

    handleFileUpload(filesUploaded) {
      const attachments = filesUploaded.map(file => ({
        url: file.url,
        filename: file.filename
      }));

      this.record.fields['Client PO Files'] = [...(this.record.fields['Client PO Files'] || []), ...attachments];
      // console.log('files object to be modified as: ', this.record.fields['Client PO Files']); // debug
    },

    handleInvoiceFilesUpload(filesUploaded) {
      const attachments = filesUploaded.map(file => ({
        url: file.url,
        filename: file.filename
      }));

      this.record.fields['Invoice Files'] = [...(this.record.fields['Invoice Files'] || []), ...attachments];
      // console.log('files object to be modified as: ', this.record.fields['Invoice Files']); // debug
    },

    deleteFile(index) {
      if (window.confirm('Are you sure you want to delete this file?')) {
        this.record.fields['Client PO Files'].splice(index, 1);
      }
    },

    deleteInvoiceFile(index) {
      if (window.confirm('Are you sure you want to delete this file?')) {
        this.record.fields['Invoice Files'].splice(index, 1);
      }
    },

    createUpdatedFields() {
      return {
        // Opp Details
        'Created By': this.record.fields['Created By'],
        'Forwarded By': this.record.fields['Forwarded By'],
        'Status': this.record.fields['Status'].value,
        'Currency': this.record.fields['Currency'].value,
        'Company': this.record.fields['Company'],
        'Contact': this.record.fields['Contact'],
        'Title': this.record.fields['Title'],
        'Public Notes': this.record.fields['Public Notes'],
        'Private Notes': this.record.fields['Private Notes'],

        // PM Details
        'PM': this.record.fields['PM'],
        'OID': this.record.fields['OID'] ? this.record.fields['OID'] : null, // use this for number input types
        'Order Start Date': this.record.fields['Order Start Date'] ? this.record.fields['Order Start Date'] : null, // saves empty dates
        'Order Delivery Date': this.record.fields['Order Delivery Date'] ? this.record.fields['Order Delivery Date'] : null, // saves empty dates
        'MateCat Link': this.record.fields['MateCat Link'],

        // Invoicing
        'Client PO': this.record.fields['Client PO'],
        'Client PO Files': this.record.fields['Client PO Files'],
        'Invoice': this.record.fields['Invoice'],
        'Invoice Files': this.record.fields['Invoice Files'],
        'VAT %': this.record.fields['VAT %'] ? this.record.fields['VAT %'] : null, // use this for number input types
        'Disc %': this.record.fields['Disc %'] ? this.record.fields['Disc %'] : null, // use this for number input types
        'Reason for Disc': this.record.fields['Reason for Disc'],

        // Post-Delivery
        'Delivery Confirmed?': this.record.fields['Delivery Confirmed?'],
        'Quality Issues Dropdown': this.record.fields['Quality Issues Dropdown'],
        'Quality Issues?': this.record.fields['Quality Issues?'],
        'Client Feedback': this.record.fields['Client Feedback'],
        'Solution': this.record.fields['Solution'],
        'Action': this.record.fields['Action'],
        'Outcome': this.record.fields['Outcome'],
      };
    },

    validateFields() {
      let errors = [];

      return errors;
    },

    async fetchRecord() {
      try {
        this.record = await this.$store.dispatch('fetchRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        this.isDataFetched = true;

      } catch (error) {
        this.$emit('showError', error.message);
        console.error(error);
      }
    },

    async updateRecord() {
      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        return;
      }

      try {
        await this.$store.dispatch('updateRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          updatedFields: this.createUpdatedFields(),
          updatingStateVariable: this.updatingStateVariable,
        });

        Object.keys(this.sectionEditMode).forEach((key) => {
          this.sectionEditMode[key] = false;
        });

        this.$emit('showAlert', 'success', 'Successfully updated record');
        this.$refs.oppDetailsJobsTab.fetchRelatedRecords();
        await this.fetchRecord();

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error updating record: ${error.message}`);
        console.error(error);
      }
    },

    async sendStatusChangeMessage(newStatus) {
      const payload = {
        company: this.company,
        contactEmail: this.contactEmail,
        quoteNumber: this.quoteNumber,
        oppUrl: this.oppUrl,
        createdBy: this.createdBy,
        currency: this.currency,
        value: this.value,
        privateNotes: this.privateNotes,
      };

      if (newStatus === 'Quote: Abandoned') {
        payload.messageType = 'abandoned';
      } else if (newStatus === 'Quote: Lost') {
        payload.messageType = 'lost';
      } else {
        return; // No need to send a message for other statuses
      }

      await sendMessageToSlack(payload);
    },
  },

  async mounted() {
    await this.fetchRecord();
    this.client = filestack.init('AajO2uLRCz1UPCKnfyHAsz');

    const quoteNo = this.record.fields['Quote No.'];
    const companyLookup = this.record.fields['Company Lookup'] ? this.record.fields['Company Lookup'].join(', ') : '';
    const contactLookup = this.record.fields['Contact Lookup'] ? this.record.fields['Contact Lookup'].join(', ') : '';
    const orderID = this.record.fields['OID'];

    let title;

    if (orderID) {
      title = `Order ${orderID} (${companyLookup}, ${contactLookup})`;
    } else {
      title = `Opp ${quoteNo} (${companyLookup}, ${contactLookup})`;
    }

    document.title = title;

    // Shows tabs when the component is mounted
    this.$nextTick(() => {
      this.tabsVisible = true;
    });

    // Initialize tooltips after ensuring the DOM is fully updated
    [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipTriggerEl => {
      new bootstrap.Tooltip(tooltipTriggerEl);
    });
  },

  created() {
    this.mixinCheckUserAuthentication();
  },
};
</script>